import React from "react";
import {AbsenceColours, AbsenceColoursGoogle, AbsenceTypeCounts} from "../Logic/yearData";
import {Chart} from "react-google-charts";
import BasicItemList, {BasicDottedListItem, BasicDottedListItemsToBasicItems} from "./BasicItemList";

export interface AbsenceTypesCountWidgetProps {
    data: AbsenceTypeCounts;
}

type GoogleChartValue = [string, string | number];

export default function AbsenceTypesCountWidget(props: AbsenceTypesCountWidgetProps) {
    const options = {
        pieHole: 0.5,
        pieSliceText: "none",
        legend: {
            position: "none"
        },
        fontSize: 16,
        fontName: "Inter",
        chartArea: {width: "100%", height: "93%"},
        colors: AbsenceColoursGoogle
    };
    const data: GoogleChartValue[] = [
        ["Absence Type", "Absence Count"],
        ["Holiday", props.data.holiday],
        ["Sick Paid", props.data.sickPaid],
        ["Sick Unpaid", props.data.sickUnpaid],
        ["Unauthorised", props.data.unauthorised],
    ];
    const total = props.data.holiday + props.data.sickPaid + props.data.sickUnpaid + props.data.unauthorised;
    const statItems: BasicDottedListItem[] = [
        {
            key: "holiday",
            name: "Holiday",
            colour: AbsenceColours.holiday,
            value: `${props.data.holiday} / ${total}`
        },
        {
            key: "sickPaid",
            name: "Sick Paid",
            colour: AbsenceColours.sickPaid,
            value: `${props.data.sickPaid} / ${total}`
        },
        {
            key: "sickUnpaid",
            name: "Sick Unpaid",
            colour: AbsenceColours.sickUnpaid,
            value: `${props.data.sickUnpaid} / ${total}`
        },
        {
            key: "unauthorised",
            name: "Unauthorised",
            colour: AbsenceColours.unauthorised,
            value: `${props.data.unauthorised} / ${total}`
        }
    ];

    return (
        <div className="dash-widget">
            <h2>Absence Breakdown </h2>
            <div className="dash-widget-subtitle">YTD overview</div>

            <div className="dash-widget-chart-container">
                <Chart
                    chartType={"PieChart"}
                    data={data}
                    options={options}
                    width="100%"
                    height="200px"
                />
            </div>
            <BasicItemList items={BasicDottedListItemsToBasicItems(statItems)} />
        </div>
    );
}