export const routeNames = {
    createAbsence: {
        path: "/annual-leave/create",
        name: "Create Annual Leave"
    },
    editAbsence: {
        path: "/annual-leave/edit",
        name: "Edit Annual Leave"
    },
    requestAbsence: {
        path: "/annual-leave/request",
        name: "Request Annual Leave"
    },
    absenceCalendar: {
        path: "/annual-leave/calendar",
        name: "Leave Calendar"
    },
    absenceOverview: {
        path: "/annual-leave/overview",
        name: "Leave Dashboard"
    },
    holidayRequestAbsenceList: {
        path: "/annual-leave/holiday/planner",
        name: "Annual Leave Planner"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
