import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AbsenceType, ListAbsencesResponse} from "../../../api/staff";

export const ABSENCE_CALENDAR_STORE = createStoreState("absence_calendar");

interface AbsenceCalendarLoading extends StoreServiceData<SortedListAbsenceResponse> {
    type: typeof ABSENCE_CALENDAR_STORE.LOADING;
}

interface AbsenceCalendarError extends StoreServiceData<SortedListAbsenceResponse> {
    type: typeof ABSENCE_CALENDAR_STORE.ERROR;
}

interface AbsenceCalendarSuccess extends StoreServiceData<SortedListAbsenceResponse> {
    type: typeof ABSENCE_CALENDAR_STORE.SUCCESS;
}

export type AbsenceCalendarDispatchTypes =
    | AbsenceCalendarLoading
    | AbsenceCalendarError
    | AbsenceCalendarSuccess;

export interface CalendarAbsenceItem {
    id?: string | number;
    allDay: boolean;
    start: Date;
    end: Date;
    title: string;
    absenceType: AbsenceType;
    isHistoric: boolean;
}

export interface SortedListAbsenceResponse extends ListAbsencesResponse {
    sortedAbsenceItems: CalendarAbsenceItem[];
}
