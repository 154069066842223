import React from "react";
import {Chart} from "react-google-charts";
import {AbsenceColours, AbsenceColoursGoogle, AbsenceYearData} from "../Logic/yearData";

export interface YearOverviewWidgetProps {
    data: AbsenceYearData;
}

export default function YearOverviewWidget(props: YearOverviewWidgetProps) {
    const options = {
        legend: {
            position: "none"
        },
        annotations: {
            textStyle: {
                color: "#fff",
                fontSize: 18,
                bold: true
            }
        },
        hAxis: {
            gridlines: {
                count: 0
            },
            minorGridlines: {
                count: 0
            }
        },
        vAxis: {
            textStyle: {}
        },
        fontSize: 16,
        fontName: "Inter",
        colors: AbsenceColoursGoogle,
        lineWidth: 1,
        pointSize: 1,
        chartArea: {width: "100%", height: "100%"}
    };

    return (
        <div className="dash-widget dash-widget-year">
            <h2>YTD Overview</h2>
            <div className="dash-widget-subtitle">Weekly analysis </div>

            <div className="dash-widget-chart-container">
                <Chart chartType={"LineChart"} data={props.data.weekData} options={options} width="100%" height="350px" />
            </div>
            <div className="widget-inline-values">
                <div className="widget-inline-value">
                    <div className="dash-widget-dot" style={{backgroundColor: AbsenceColours.holiday}}></div>
                    <div className="widget-inline-value-name">Holiday</div>
                    <div className="widget-inline-value-value">{props.data.yearTotals.holiday}</div>
                </div>
                <div className="widget-inline-value">
                    <div className="dash-widget-dot" style={{backgroundColor: AbsenceColours.sickPaid}}></div>
                    <div className="widget-inline-value-name">Sick Paid</div>
                    <div className="widget-inline-value-value">{props.data.yearTotals.sickPaid}</div>
                </div>
                <div className="widget-inline-value">
                    <div className="dash-widget-dot" style={{backgroundColor: AbsenceColours.sickUnpaid}}></div>
                    <div className="widget-inline-value-name">Sick Unpaid</div>
                    <div className="widget-inline-value-value">{props.data.yearTotals.sickUnpaid}</div>
                </div>
                <div className="widget-inline-value">
                    <div className="dash-widget-dot" style={{backgroundColor: AbsenceColours.unauthorised}}></div>
                    <div className="widget-inline-value-name">Unauthorised</div>
                    <div className="widget-inline-value-value">{props.data.yearTotals.unauthorised}</div>
                </div>
            </div>
        </div>
    );
}