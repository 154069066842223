import React, {useState} from "react";
import FilterContainer from "../../../Filters/FilterContainer";
import HolidayListFilters from "./HolidayListFilters";
import {ListUserAbsenceRequests} from "../../../../api/staff";
import {useDispatch} from "react-redux";
import {getHolidayRequestsForCalendar} from "../../../../store/holidayRequestAbsenceList/actions/HolidayRequestAbsenceListActions";
import HolidayListTable from "./HolidayListTable";
import {SortedListHolidayResponse} from "../../../../store/holidayRequestAbsenceList/actions/HolidayRequestAbsenceListActionTypes";
import HolidayListActions, {HolidayView} from "./HolidayListActions";
import moment from "moment";
import HolidayListCalendarView from "./HolidayListCalendarView";

function HolidayListWrapper(props: SortedListHolidayResponse) {
    const dispatch = useDispatch();
    const [request, setRequest] = useState<ListUserAbsenceRequests>();
    const [currentView, setCurrentView] = useState<HolidayView>();

    function onRequestChanged(entity: ListUserAbsenceRequests) {
        if (JSON.stringify(entity) === JSON.stringify(request)) return;
        setRequest(entity);
        dispatch(getHolidayRequestsForCalendar(entity));
    }

    function renderCorrectView(view: HolidayView | undefined) {
        if (!view) return;

        switch (view) {
            case HolidayView.List:
                return <HolidayListTable {...props} />;

            case HolidayView.Calendar:
                return <HolidayListCalendarView response={props} initialDate={getInitialDate()} />;
        }
    }

    function getInitialDate() {
        if (!request) return moment().unix();

        return request.startDate;
    }

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <HolidayListFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <HolidayListActions onViewChanged={(view) => setCurrentView(view)} />
            {renderCorrectView(currentView)}
        </React.Fragment>
    );
}
export default HolidayListWrapper;
