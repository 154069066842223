import React, {useEffect} from "react";
import {useAbsenceStatsPage} from "./Hooks/useAbsenceStatsPage";
import {WithServiceState} from "store-fetch-wrappers";
import AbsenceStatsWrapper from "./Components/AbsenceStatsWrapper";
import BackgroundContainer from "../../Containers/BackgroundContainer";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
import {useDispatch} from "react-redux";
import {getAbsenceStatsForDashboard} from "../../../store/absenceStats/actions/AbsenceStatsActions";

const ServiceWrapper = WithServiceState(AbsenceStatsWrapper);

function AbsenceStats() {
    const {absenceStatsStore} = useAbsenceStatsPage();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAbsenceStatsForDashboard());
    }, [dispatch]);

    return (
        <React.Fragment>
            <TopBar titleText="Leave Dashboard" />
            <BackgroundContainer background={"background-stars"}>
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...absenceStatsStore}
                />
            </BackgroundContainer>
        </React.Fragment>
    );
}
export default AbsenceStats;
