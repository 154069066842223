import React, {useEffect} from "react";
import RequestTimeOffForm from "./Components/RequestTimeOffForm";
import {useUserData} from "../../Hooks/useUserData";
import {useRouteSetup} from "../Hooks/useRouteSetup";
import useMcConfig from "../../Hooks/useMcConfig";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

function RequestTimeOff() {
    const user = useUserData();
    const {setRoutes} = useRouteSetup();
    const {config} = useMcConfig();

    useEffect(() => {
        if (!config.auth) return;
        if (user.username.length === 0) return;
        setRoutes(user, config);
    }, [user, config]);

    return (
        <React.Fragment>
            <TopBar titleText="Request Time Off" />
            <div className="page-container pt-4">
                <RequestTimeOffForm />
            </div>
        </React.Fragment>
    );
}

export default RequestTimeOff;
