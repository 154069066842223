import React, {ReactNode} from "react";

export interface BasicItemListItem {
    key: string;
    name: ReactNode;
    value: ReactNode;
}

export interface BasicItemListProps {
    items: BasicItemListItem[];
}

export default function BasicItemList(props: BasicItemListProps) {
    return (
        <div className="basic-items-list">
            {props.items.map((item) => {
                return (
                    <div key={item.key} className="basic-item-row">
                        <div className="basic-item-name">{item.name}</div>
                        <div className="basic-item-divider">
                            <div className="basic-item-divider-line"></div>
                        </div>
                        <div className="basic-item-value">{item.value}</div>
                    </div>
                );
            })}
        </div>
    );
}

export interface BasicDottedListItem {
    key: string;
    colour: string;
    name: ReactNode;
    value: ReactNode;
}

export function BasicDottedListItemsToBasicItems(items: BasicDottedListItem[]): BasicItemListItem[] {
    return items.map((item) => {
        return {
            key: item.key,
            name: (
                <div className="basic-item-dot-name-wrapper">
                    <div className="basic-item-dot" style={{backgroundColor: item.colour}}></div>
                    <div className="basic-item-dot-name">{item.name}</div>
                </div>
            ),
            value: item.value,
        };
    });
}