import React from "react";
import {AbsenceDashboardStatsResponse} from "../../../../api/staff";
import {AbsenceColours, getAbsenceYearData} from "../Logic/yearData";
import YearOverviewWidget from "./YearOverviewWidget";
import AbsenceTypesCountWidget from "./AbsenceTypesCountWidget";
import AbsenceTypeByDayWidget from "./AbsenceTypeByDayWidget";

function AbsenceStatsWrapper(props: AbsenceDashboardStatsResponse) {
    const yearData = getAbsenceYearData(props.absenceCountsYear);

    return (
        <div className="dash-container">
            <div className="dash-widgets">
                <div className="dash-row-overview">
                    <YearOverviewWidget data={yearData} />
                    <AbsenceTypesCountWidget data={yearData.yearTotals} />
                </div>

                <div className="dash-row-byday">
                    <AbsenceTypeByDayWidget name="Holiday" colour={AbsenceColours.holiday} data={yearData.byDayCounts.holiday} />
                    <AbsenceTypeByDayWidget name="Sick Paid" colour={AbsenceColours.sickPaid} data={yearData.byDayCounts.sickPaid} />
                    <AbsenceTypeByDayWidget name="Sick Unpaid" colour={AbsenceColours.sickUnpaid} data={yearData.byDayCounts.sickUnpaid} />
                    <AbsenceTypeByDayWidget name="Unauthorised" colour={AbsenceColours.unauthorised} data={yearData.byDayCounts.unauthorised} />
                </div>
            </div>
        </div>
    );
}

export default AbsenceStatsWrapper;
