import {Link, useLocation} from "react-router-dom";
import React from "react";
import {RootStore} from "../../../../../../store/Store";
import {useSelector} from "react-redux";

export default function HeaderTabs() {
    const location = useLocation();
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];

    return (
        <div className="top_tabs">
            {navItems.map((item) => {
                if (item.path === undefined) return undefined;
                const isActive = item.path.startsWith(location.pathname ?? " ");

                return (
                    <Link
                        className={`top_tab ${isActive ? "active" : ""}`}
                        key={item.path}
                        to={item.path}
                    >
                        {item.name}
                    </Link>
                );
            })}
        </div>
    );
}
