import React from "react";
import {ByDayCounts} from "../Logic/yearData";
import {Chart} from "react-google-charts";

export interface AbsenceTypeByDayWidgetProps {
    name: string;
    colour: string;
    data: ByDayCounts;
}

type GoogleChartValue = [string, string | number];

export default function AbsenceTypeByDayWidget(props: AbsenceTypeByDayWidgetProps) {
    const data: GoogleChartValue[] = [
        ["Day", "Count"],
        ["Mon", props.data.monday],
        ["Tue", props.data.tuesday],
        ["Wed", props.data.wednesday],
        ["Thu", props.data.thursday],
        ["Fri", props.data.friday],
        ["Sat", props.data.saturday],
        ["Sun", props.data.sunday],
    ];
    const colours = [props.colour, props.colour, props.colour, props.colour, props.colour, props.colour, props.colour];
    const [minValue, maxValue] = getMinMax(props.data);
    const options = {
        legend: {
            position: "none"
        },
        annotations: {
            textStyle: {
                color: "#fff",
                fontSize: 18,
                bold: true
            }
        },
        hAxis: {
            gridlines: {
                count: 0
            },
            minorGridlines: {
                count: 0
            },
        },
        vAxis: {
            textStyle: {},
            minValue: 0,
            viewWindowMode:'explicit',
            viewWindow: {
                min: minValue,
                max: maxValue
            }
        },
        fontSize: 16,
        fontName: "Inter",
        colors: colours,
        lineWidth: 4,
        pointSize: 6,
        chartArea: { left: 50, width: "100%", height: "80%" }
    };

    return (
        <div className="dash-widget dash-widget-year">
            <h2>{props.name}</h2>
            <div className="dash-widget-subtitle">Count per day</div>

            <div className="dash-widget-chart-container">
                <Chart chartType={"LineChart"} data={data} options={options} width="100%" height="250px" />
            </div>
        </div>
    );
}

function getMinMax(data: ByDayCounts): [number, number] {
    const values = [
        data.monday,
        data.tuesday,
        data.wednesday,
        data.thursday,
        data.friday,
        data.saturday,
        data.sunday
    ];

    let min = Math.min(...values);
    let max = Math.max(...values);

    if (min === max)
        max += 2;

    min -= min * 0.05;
    max += max * 0.05;
    min = Math.floor(min);
    max = Math.ceil(max);

    if (min < 0)
        min = 0;

    return [min, max];
}