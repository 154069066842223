import {Dispatch} from "redux";
import {
    CalendarHolidayItem,
    HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE,
    HolidayRequestAbsenceCalendarDispatchTypes
} from "./HolidayRequestAbsenceListActionTypes";
import {
    ListUserAbsenceRequests,
    ListUserAbsenceRequestsResponse,
    UserAbsenceRequest
} from "../../../api/staff";
import {postDataToServiceWithData} from "store-fetch-wrappers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import moment from "moment";
import {createBlankListUserAbsenceRequestsResponse} from "../reducer/HolidayRequestAbsenceListReducer";
import {showErrorToast} from "../../../utils/toastUtils";
import {getUiFriendlyText} from "../../../utils/textUtils";

export function nullifyHolidayRequestsCalendarStore() {
    return async (dispatch: Dispatch<HolidayRequestAbsenceCalendarDispatchTypes>) => {
        dispatch({
            type: HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE.SUCCESS,
            error: null,
            loading: false,
            data: createBlankListUserAbsenceRequestsResponse()
        });
    };
}

export function getHolidayRequestsForCalendar(request: ListUserAbsenceRequests) {
    return async (dispatch: Dispatch<HolidayRequestAbsenceCalendarDispatchTypes>) => {
        try {
            const updatedReq: ListUserAbsenceRequests = {
                ...request,
                startDate: moment.unix(request.startDate).subtract(8, "day").unix(),
                endDate: moment.unix(request.endDate).add(8, "day").unix()
            };

            const data = await postDataToServiceWithData(
                HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.listAbsenceRequests(updatedReq),
                statusCodeCallback
            );

            if (!data) {
                showErrorToast("Could not get holiday request from service.");
                return;
            }

            dispatch({
                type: HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE.SUCCESS,
                error: null,
                loading: false,
                data: {
                    ...data,
                    sortedHolidayItems: sortAbsenceRequestsToCalendarAbsenceItems(data)
                }
            });
        } catch (e: any) {
            dispatch({
                type: HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

function sortAbsenceRequestsToCalendarAbsenceItems(
    resp: ListUserAbsenceRequestsResponse
): CalendarHolidayItem[] {
    if (!resp.requests) return [];
    return resp.requests.map((item) => {
        const isHistoric = isAbsenceHistoric(item);

        return {
            id: item.id.toString(),
            title: getTitleForHolidayCalendarItem(item, isHistoric),
            allDay: false,
            start: moment(item.startDate * 1000).toDate(),
            end: moment(item.endDate * 1000).toDate(),
            requestStatus: item.status,
            isHistoric
        };
    });
}

function getTitleForHolidayCalendarItem(request: UserAbsenceRequest, isHistoric: boolean) {
    const baseTitle = `${request.name} - Holiday - ${getUiFriendlyText(request.status)}`;
    if (isHistoric) {
        return `${baseTitle} - Historic`;
    }

    return baseTitle;
}

/** Is only historic if both the start and end date are after today date */
function isAbsenceHistoric(absence: UserAbsenceRequest): boolean {
    const now = moment().startOf("day");

    return absence.startDate < now.unix() && absence.endDate < now.unix();
}
