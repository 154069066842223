import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AbsenceDashboardStatsResponse} from "../../../api/staff";

export const ABSENCE_STATS_STORE = createStoreState("absence_store");

interface AbsenceStatsLoading extends StoreServiceData<AbsenceDashboardStatsResponse> {
    readonly type: typeof ABSENCE_STATS_STORE.LOADING;
}

interface AbsenceStatsError extends StoreServiceData<AbsenceDashboardStatsResponse> {
    readonly type: typeof ABSENCE_STATS_STORE.ERROR;
}

interface AbsenceStatsSuccess extends StoreServiceData<AbsenceDashboardStatsResponse> {
    readonly type: typeof ABSENCE_STATS_STORE.SUCCESS;
}

export type AbsenceStatsDispatchTypes =
    | AbsenceStatsLoading
    | AbsenceStatsError
    | AbsenceStatsSuccess;
