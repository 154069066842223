import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {ABSENCE_STATS_STORE} from "../actions/AbsenceStatsActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";
import {AbsenceDashboardStatsResponse} from "../../../api/staff";

const defaultState = createDefaultStoreState<AbsenceDashboardStatsResponse>(generateBlankAbsenceStatsBlock());

const absenceStatsReducer = (
    state: StoreServiceData<AbsenceDashboardStatsResponse> = defaultState,
    action: ServiceActionTypes<AbsenceDashboardStatsResponse>
) => createReducer(state, action, ABSENCE_STATS_STORE, () => showErrorToast(action.error));

export default absenceStatsReducer;

export function generateBlankAbsenceStatsBlock(): AbsenceDashboardStatsResponse {
    return {
        absenceCounts: {},
        absenceCountsYear: []
    };
}
