import React, {useEffect} from "react";
import {EventClickArg} from "@fullcalendar/react";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory, useLocation} from "react-router-dom";
import {useUserData} from "../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../api/staff";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {useAbsenceHooks} from "../../../Hooks/useAbsenceHooks";
import moment from "moment";
import {formatUnixToMMMMYYYY} from "../../../../utils/momentUtils";
import {useQuery} from "../../../Hooks/useQuery";
import {useFullCalendar} from "../../../FullCalendar/Hooks/useFullCalendar";
import useMcConfig from "../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";
import {CalendarApi} from "@fullcalendar/core";

export function useAbsenceCalendarView() {
    const {renderEventContent} = useFullCalendar();
    const calendarRef = React.createRef<any>();
    const location = useLocation();
    const history = useHistory();
    const user = useUserData();
    const query = useQuery();
    const {fullPath} = usePageUrl();
    const {createAbsence, requestHoliday} = useAbsenceHooks();
    const {config} = useMcConfig();

    const now = new Date();
    const monthParam = query.get("month");
    let currentMonth = now.getMonth();
    if (monthParam) currentMonth = +monthParam;

    let currentYear = now.getFullYear();
    const yearParam = query.get("year");
    if (yearParam) currentYear = +yearParam;

    const nextMonth = moment().set({year: currentYear, month: currentMonth}).add(1, "month");
    const prevMonth = moment().set({year: currentYear, month: currentMonth}).subtract(1, "month");

    useEffect(() => {
        const calendarApi: CalendarApi | undefined = calendarRef.current?.getApi();
        if (!calendarApi) return;

        const targetDate = moment().set({year: currentYear, month: currentMonth});
        calendarApi.gotoDate(targetDate.unix() * 1000);
    }, [calendarRef.current, currentMonth, currentYear]);

    function viewAbsence(args: EventClickArg) {
        const calendarApi: CalendarApi | undefined = calendarRef.current?.getApi();
        if (!calendarApi) return;
        const accessLevel = getUserAccessLevel(user, config);
        if (accessLevel !== StaffAccessLevel.SystemAdministrator) {
            calendarApi.unselect();
            return;
        }
        localStorage.setItem("absence_redirect", fullPath);

        const {id} = args.event;
        history.push({
            pathname: `${routeNames.editAbsence.path}/${id}`
        });
    }

    function updateUrlQuery(targetMonth: number, targetYear: number) {
        const params = new URLSearchParams(location.search);
        params.set("month", targetMonth.toString());
        params.set("year", targetYear.toString());

        history.push({
            search: params.toString()
        });
    }

    function goToNextMonth(): void {
        updateUrlQuery(nextMonth.month(), nextMonth.year());
    }

    function goToPreviousMonth(): void {
        updateUrlQuery(prevMonth.month(), prevMonth.year());
    }

    return {
        calendarRef,
        viewAbsence,
        renderEventContent,
        requestHoliday,
        createAbsence,
        isJanuary: currentMonth === 0,
        isDecember: currentMonth === 11,
        previousMonth: goToPreviousMonth,
        nextMonth: goToNextMonth,
        previousMonthText: formatUnixToMMMMYYYY(prevMonth.unix()),
        nextMonthText: formatUnixToMMMMYYYY(nextMonth.unix()),
        currentMonthText: formatUnixToMMMMYYYY(moment().set({year: currentYear, month: currentMonth}).unix())
    };
}
