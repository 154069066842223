import React from "react";

export interface FullCalendarEventInfo {
    event: {
        title: string;
        extendedProps?: {
            absenceType?: string;
            requestStatus?: string;
        }
    };
}

export function useFullCalendar() {
    function renderEventContent(eventInfo: FullCalendarEventInfo) {
        let className = "calendar-event ";
        if (eventInfo.event?.extendedProps?.absenceType)
            className += eventInfo.event.extendedProps.absenceType.toLowerCase() + " ";
        if (eventInfo.event?.extendedProps?.requestStatus)
            className += eventInfo.event.extendedProps.requestStatus.toLowerCase();

        return (
            <div className={className}>
                {eventInfo.event.title}
            </div>
        );
    }

    return {
        renderEventContent
    };
}
