import {AbsenceDayCounts, AbsenceType} from "../../../../api/staff";
import moment from "moment";

export type GoogleChartValues = [string, string | number, string | number, string | number, string | number];

export const AbsenceColours = {
    holiday: "#64c246",
    sickPaid: "#18c4fb",
    sickUnpaid: "#d945fe",
    unauthorised: "#fb1818"
};

export const AbsenceColoursGoogle = [
    AbsenceColours.holiday,
    AbsenceColours.sickPaid,
    AbsenceColours.sickUnpaid,
    AbsenceColours.unauthorised
];

export interface AbsenceTypeCounts {
    holiday: number;
    sickPaid: number;
    sickUnpaid: number;
    unauthorised: number;
}

export interface ByDayCounts {
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
}

export interface AbsenceByDayCounts {
    holiday: ByDayCounts;
    sickPaid: ByDayCounts;
    sickUnpaid: ByDayCounts;
    unauthorised: ByDayCounts;
}

export interface AbsenceYearData {
    weekData: GoogleChartValues[];
    yearTotals: AbsenceTypeCounts;
    byDayCounts: AbsenceByDayCounts;
}

export function getAbsenceYearData(absences: AbsenceDayCounts[] | undefined): AbsenceYearData {
    const dayData: GoogleChartValues[] = [[
        "Week", "Holiday", "Sick Paid", "Sick Unpaid", "Unauthorised"
    ]];
    const yearTotals = {
        holiday: 0,
        sickPaid: 0,
        sickUnpaid: 0,
        unauthorised: 0
    };
    const byDayCounts = {
        holiday: {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0
        },
        sickPaid: {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0
        },
        sickUnpaid: {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0
        },
        unauthorised: {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0
        }
    };
    const interimWeekTotals = new Map<number, AbsenceTypeCounts>();

    if (!absences || absences.length === 0) {
        dayData.push([
            moment().format("DD/MM/YY"),
            0,
            0,
            0,
            0
        ]);

        return {
            weekData: dayData,
            yearTotals,
            byDayCounts
        };
    }
    absences.sort((a, b) => a.date - b.date);

    for (const block of absences) {
        const date = moment.unix(block.date);
        const day = date.isoWeekday();
        const week = date.isoWeek();
        const holiday = block.counts[AbsenceType.Holiday] || 0;
        const sickPaid = block.counts[AbsenceType.SickPaid] || 0;
        const sickUnpaid = block.counts[AbsenceType.SickUnpaid] || 0;
        const unauthorised = block.counts[AbsenceType.Unauthorised] || 0;

        let weekData = interimWeekTotals.get(week)
        if (!weekData) {
            weekData = {
                holiday: 0,
                sickPaid: 0,
                sickUnpaid: 0,
                unauthorised: 0
            };
            interimWeekTotals.set(week, weekData);
        }
        weekData.holiday += holiday;
        weekData.sickPaid += sickPaid;
        weekData.sickUnpaid += sickUnpaid;
        weekData.unauthorised += unauthorised;

        yearTotals.holiday += holiday;
        yearTotals.sickPaid += sickPaid;
        yearTotals.sickUnpaid += sickUnpaid;
        yearTotals.unauthorised += unauthorised;

        switch (day) {
            case 1:
                byDayCounts.holiday.monday += holiday;
                byDayCounts.sickPaid.monday += sickPaid;
                byDayCounts.sickUnpaid.monday += sickUnpaid;
                byDayCounts.unauthorised.monday += unauthorised;
                break;
            case 2:
                byDayCounts.holiday.tuesday += holiday;
                byDayCounts.sickPaid.tuesday += sickPaid;
                byDayCounts.sickUnpaid.tuesday += sickUnpaid;
                byDayCounts.unauthorised.tuesday += unauthorised;
                break;
            case 3:
                byDayCounts.holiday.wednesday += holiday;
                byDayCounts.sickPaid.wednesday += sickPaid;
                byDayCounts.sickUnpaid.wednesday += sickUnpaid;
                byDayCounts.unauthorised.wednesday += unauthorised;
                break;
            case 4:
                byDayCounts.holiday.thursday += holiday;
                byDayCounts.sickPaid.thursday += sickPaid;
                byDayCounts.sickUnpaid.thursday += sickUnpaid;
                byDayCounts.unauthorised.thursday += unauthorised;
                break;
            case 5:
                byDayCounts.holiday.friday += holiday;
                byDayCounts.sickPaid.friday += sickPaid;
                byDayCounts.sickUnpaid.friday += sickUnpaid;
                byDayCounts.unauthorised.friday += unauthorised;
                break;
            case 6:
                byDayCounts.holiday.saturday += holiday;
                byDayCounts.sickPaid.saturday += sickPaid;
                byDayCounts.sickUnpaid.saturday += sickUnpaid;
                byDayCounts.unauthorised.saturday += unauthorised;
                break;
            case 7:
                byDayCounts.holiday.sunday += holiday;
                byDayCounts.sickPaid.sunday += sickPaid;
                byDayCounts.sickUnpaid.sunday += sickUnpaid;
                byDayCounts.unauthorised.sunday += unauthorised;
                break;
        }
    }

    interimWeekTotals.forEach((data, week) => {
        dayData.push([
            "w/c " + moment().day("monday").isoWeek(week).format("DD MMM YYYY"),
            data.holiday,
            data.sickPaid,
            data.sickUnpaid,
            data.unauthorised
        ]);
    })

    return {
        weekData: dayData,
        yearTotals,
        byDayCounts
    };
}