import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {ListUserAbsenceRequestsResponse, UserAbsenceRequestStatus} from "../../../api/staff";

export const HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE = createStoreState(
    "holiday_request_absence_calendar"
);

interface HolidayRequestAbsenceCalendarLoading extends StoreServiceData<SortedListHolidayResponse> {
    type: typeof HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE.LOADING;
}

interface HolidayRequestAbsenceCalendarError extends StoreServiceData<SortedListHolidayResponse> {
    type: typeof HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE.ERROR;
}

interface HolidayRequestAbsenceCalendarSuccess extends StoreServiceData<SortedListHolidayResponse> {
    type: typeof HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE.SUCCESS;
}

export type HolidayRequestAbsenceCalendarDispatchTypes =
    | HolidayRequestAbsenceCalendarLoading
    | HolidayRequestAbsenceCalendarError
    | HolidayRequestAbsenceCalendarSuccess;

export interface SortedListHolidayResponse extends ListUserAbsenceRequestsResponse {
    sortedHolidayItems: CalendarHolidayItem[];
}

export interface CalendarHolidayItem {
    id?: string | number;
    allDay: boolean;
    start: Date;
    end: Date;
    title: string;
    requestStatus: UserAbsenceRequestStatus;
    isHistoric: boolean;
}
