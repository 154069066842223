import React, {useState} from "react";
import {NavBarContext} from "./Components/Sidebar/navContext";
import NavBar from "./Components/Sidebar/NavBar";
import HeaderTabs from "./Components/Header/HeaderTabs";

export interface LayoutProps {
    compiler?: string;
    framework?: string;
    children: JSX.Element;
}

const MedicareLayout = ({children}: LayoutProps) => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <NavBarContext.Provider value={{show: showMenu, setShow: setShowMenu}}>
            <div className="nav_wrapper">
                <NavBar />
                <HeaderTabs />
                <div className="layout-wrapper" id="main-panel">
                    {children}
                </div>
            </div>
        </NavBarContext.Provider>
    );
};

export default MedicareLayout;
