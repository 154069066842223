import React from "react";
import {UserAbsence} from "../../../../api/staff";
import {useAbsenceForm} from "../Hooks/useAbsenceForm";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import StaffDropdown from "../../../Dropdown/StaffDropdown";
import {DebounceInput} from "react-debounce-input";
import useUnixUtils from "../../../Hooks/useUnixUtils";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {showSuccessToast} from "../../../../utils/toastUtils";
import PulseDatePicker from "../../../DatePicker/PulseDatePicker";
import ButtonWithActionConfirmation from "../../../Button/ButtonWithActionConfirmation";
import PulseEnumDropdown from "../../../Dropdown/PulseEnumDropdown";
import {useUserAbsence} from "../../../Hooks/useUserAbsence";
import {AbsenceTypeFilters} from "../../AbsenceCalendar/Components/AbsenceCalendarFilters";

function EditAbsenceForm(props: UserAbsence) {
    const {
        updateAbsence,
        getAbsenceTypeFromString,
        save,
        backToAbsenceCalendar,
        deleteAbsence,
        getLeaveType,
        getCorrectDeletionText
    } = useAbsenceForm();
    const {getNumberOfDaysBetweenDays} = useUserAbsence();
    const {dateToMoment} = useUnixUtils();

    return (
        <React.Fragment>
            <FormHeader headerName={"Create Leave"} />
            <FormRow rowName={"Staff Member"} columnDetailClassName={"pl-0 pr-0"}>
                <StaffDropdown
                    searchable={true}
                    onChange={(item) => {
                        if (!item) return;
                        updateAbsence({
                            ...props,
                            username: item.staffId,
                            name: item.staffName
                        });
                    }}
                    clearable={false}
                    username={props.username}
                    disabled={false}
                />
            </FormRow>
            <FormRow rowName={"Leave Type"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseEnumDropdown
                    onChange={(item) => {
                        if (!item) return;
                        const absenceType = getAbsenceTypeFromString(item.value.toString());
                        updateAbsence({
                            ...props,
                            type: absenceType
                        });
                    }}
                    searchable={false}
                    clearable={false}
                    disabled={false}
                    enumOptions={AbsenceTypeFilters}
                    value={getLeaveType(props.type)}
                />
            </FormRow>
            <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    style={{minHeight: `100px`}}
                    className="input-fields text-area-inputs"
                    element={"textarea"}
                    placeholder={"Enter comments here..."}
                    value={props.reason}
                    debounceTimeout={300}
                    onChange={(event) => {
                        const {value} = event.target;
                        updateAbsence({
                            ...props,
                            reason: value
                        });
                    }}
                />
            </FormRow>
            <FormRow rowName={"Leave Start Date"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseDatePicker
                    initialDate={props.startDate}
                    onChange={(date) => {
                        if (!date) return;
                        const startOfDay = dateToMoment(date).clone().startOf("day");
                        const numberOfDays = getNumberOfDaysBetweenDays(
                            startOfDay.unix(),
                            props.endDate
                        );
                        updateAbsence({
                            ...props,
                            startDate: startOfDay.unix(),
                            numberDays: numberOfDays
                        });
                    }}
                />
            </FormRow>
            <FormRow rowName={"Leave Finish Date"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseDatePicker
                    initialDate={props.endDate}
                    onChange={(date) => {
                        if (!date) return;
                        const endOfDay = dateToMoment(date).clone().endOf("day");
                        const numberOfDays = getNumberOfDaysBetweenDays(
                            props.startDate,
                            endOfDay.unix()
                        );
                        updateAbsence({
                            ...props,
                            endDate: endOfDay.unix(),
                            numberDays: numberOfDays
                        });
                    }}
                />
            </FormRow>
            <FormRow rowName={"Duration of Leave (Days)"} columnDetailClassName={"pl-0 pr-0"}>
                <input
                    className="input-fields"
                    placeholder={"Enter reason here..."}
                    value={props.numberDays}
                    type={"tel"}
                    onChange={(event) => {
                        const {value} = event.target;
                        updateAbsence({
                            ...props,
                            numberDays: +value
                        });
                    }}
                />
            </FormRow>
            <div className="row-modifier">
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={async () => {
                            const success = await save(props);

                            if (!success) return;
                            showSuccessToast(`Success! Absence saved for ${props.name}`);
                            backToAbsenceCalendar();
                        }}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={backToAbsenceCalendar}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                    <React.Fragment>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {props?.id > 0 && (
                            <ButtonWithActionConfirmation
                                buttonText={`Delete Leave`}
                                action={async () => deleteAbsence(props)}
                                buttonColour={ButtonColourOptions.Red}
                                itemName={`this leave (${getCorrectDeletionText(props.type)})`}
                                modalTitle={"Delete Leave"}
                            />
                        )}
                    </React.Fragment>
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
}

export default EditAbsenceForm;
