import React from "react";

export interface PageHeadingProps {
    title: string;
    subTitle?: string;
}

export default function TopBarTitle(props: PageHeadingProps) {
    return (
        <div className="top-bar-title">
            <h1>{props.title}</h1>
            {props.subTitle && <p className="subtitle">{props.subTitle}</p>}
        </div>
    );
}
