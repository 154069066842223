import React from "react";

export interface CompanyLogoProps {
    size?: string | number;
    className?: string;
}

export default function CompanyLogo({size = 24, className}: CompanyLogoProps) {
    const actualSize = size || "1rem";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={actualSize}
            viewBox="0 0 455.57 65.46"
            className={className}
        >
            <defs>
                <style>{`
            .cls-1 { fill: currentColor; }
            .cls-2 { fill: #D0D700; }
        `}</style>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        className="cls-1"
                        d="M119.49,43l-.12-21.21L106.74,43,93.82,22.35V43H83.35V3h9.32l14.07,23.1L120.46,3h9.32l.12,40Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M164.32,30.24H141.79c.92,3.15,3.61,5,7.61,5a10,10,0,0,0,7.15-2.69l5.71,5.95c-3,3.31-7.31,5-13.2,5-11,0-18.19-6.8-18.19-16.07s7.26-15.95,17-15.95c9.15,0,16.58,5.83,16.58,16.07C164.49,28.36,164.38,29.39,164.32,30.24Zm-22.7-5.71h12.7a6.45,6.45,0,0,0-12.7,0Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M197.89.57V43H187.54V39.91c-2.06,2.46-5.09,3.6-9.09,3.6-8.46,0-15.27-6.18-15.27-16.07S170,11.49,178.45,11.49c3.6,0,6.52,1,8.58,3.2V.57ZM187.2,27.44c0-4.69-2.8-7.32-6.46-7.32s-6.52,2.63-6.52,7.32,2.8,7.44,6.52,7.44S187.2,32.19,187.2,27.44Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M200.3,12h10.86V43H200.3Zm10.86-6.58a5.43,5.43,0,1,0-5.43,5.43A5.43,5.43,0,0,0,211.16,5.43Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M211.68,27.44c0-9.32,7.37-15.95,17.78-15.95,7,0,12.4,3.15,14.52,8.52l-8.4,4.29c-1.49-2.92-3.66-4.18-6.18-4.18-3.77,0-6.75,2.63-6.75,7.32s3,7.44,6.75,7.44c2.52,0,4.69-1.26,6.18-4.18L244,35c-2.12,5.37-7.49,8.52-14.52,8.52C219.05,43.51,211.68,36.88,211.68,27.44Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M272.57,25.78V43H262.45V39c-1.54,3-4.63,4.52-9.14,4.52-7.44,0-11.55-4.17-11.55-9.55,0-5.72,4.17-9.26,13.77-9.26h6.18c-.17-3.15-2.29-4.92-6.58-4.92a14.43,14.43,0,0,0-8.4,2.69l-3.66-7.38c3.54-2.34,8.81-3.6,13.67-3.6C266.8,11.49,272.57,16,272.57,25.78Zm-10.86,7.27V30.36H257c-3.49,0-4.74,1.26-4.74,3.14s1.48,3.15,4,3.15A5.36,5.36,0,0,0,261.71,33.05Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M295.62,11.49v9.78a18.73,18.73,0,0,0-2.46-.17c-4.4,0-7.43,2.23-7.43,7.66V43H274.86V12h10.35v3.71C287.44,12.92,291,11.49,295.62,11.49Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M326.73,30.24H304.2c.91,3.15,3.6,5,7.6,5A9.93,9.93,0,0,0,319,32.53l5.72,5.95c-3,3.31-7.32,5-13.21,5-11,0-18.18-6.8-18.18-16.07s7.26-15.95,17-15.95c9.15,0,16.58,5.83,16.58,16.07C326.9,28.36,326.78,29.39,326.73,30.24ZM304,24.53h12.69a6.44,6.44,0,0,0-12.69,0Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M396.51,44.32,384.26,24.77V43.69H372.41V2.28h10.4l13.67,22.45L409.82,2.28h10.4l.12,41.41H408.55l-.1-19.41Zm13.42-2h9l-.11-38.64h-8.23L396.5,27.41,382,3.66H373.8V42.3h9.08V19.94l13.6,21.72,13.33-22.37Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M372.08,43.69H338.56V2.28h32.78V12.41H351.15v5.36h17.78v9.85H351.15v5.93h20.93ZM340,42.3h30.74V34.94H349.77v-8.7h17.78V19.16H349.77V11H370V3.66H340Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M437.15,44.49c-6.54,0-13.32-1.78-17.26-4.53l-.47-.33L423.7,30l.68.46a24.57,24.57,0,0,0,12.83,3.83c2.66,0,5.82-.45,5.82-2.57,0-1.95-3.38-2.71-7.31-3.58-6.51-1.46-15.44-3.45-15.44-13,0-8.31,7.23-13.67,18.42-13.67a30.53,30.53,0,0,1,14.87,3.58l.52.3-4,9.68-.68-.37a22.87,22.87,0,0,0-10.76-3c-2.63,0-5.77.5-5.77,2.91,0,1.84,3.32,2.56,7.17,3.39,6.56,1.42,15.53,3.37,15.53,12.9C455.57,37.15,450.75,44.49,437.15,44.49Zm-16-5.35c3.78,2.42,10,4,16,4,10.35,0,17-4.8,17-12.23,0-8.41-8-10.15-14.43-11.54-4.44-1-8.27-1.8-8.27-4.75,0-1.61.93-4.3,7.16-4.3a23.76,23.76,0,0,1,10.74,2.82l3-7.14a29.67,29.67,0,0,0-13.64-3.1c-10.51,0-17,4.71-17,12.29,0,8.44,8,10.21,14.37,11.64,4.5,1,8.39,1.88,8.39,4.94,0,1.8-1.25,3.95-7.21,3.95a26.11,26.11,0,0,1-12.9-3.62Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M90.36,61h.9v3.37A4.85,4.85,0,0,1,88,65.46a4.5,4.5,0,1,1,0-9,4.32,4.32,0,0,1,3.28,1.27l-.58.59a3.74,3.74,0,1,0-2.68,6.29,3.76,3.76,0,0,0,2.31-.68Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M105,65.38l-2-2.84a5.43,5.43,0,0,1-.71,0H99.89v2.8H99V56.54h3.31c2.25,0,3.61,1.14,3.61,3a2.74,2.74,0,0,1-2,2.78l2.16,3Zm0-5.81c0-1.41-.94-2.22-2.7-2.22H99.89v4.43h2.35C104,61.78,104.94,61,104.94,59.57Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M112.35,61a4.64,4.64,0,1,1,4.65,4.5A4.45,4.45,0,0,1,112.35,61Zm8.35,0a3.72,3.72,0,1,0-3.7,3.66A3.58,3.58,0,0,0,120.7,61Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M128.71,61.61V56.54h.93v5c0,2.1,1,3.05,2.7,3.05s2.72-1,2.72-3.05v-5H136v5.07c0,2.53-1.36,3.85-3.63,3.85S128.71,64.14,128.71,61.61Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M150.76,59.57c0,1.88-1.36,3-3.61,3h-2.38v2.79h-.93V56.54h3.31C149.4,56.54,150.76,57.68,150.76,59.57Zm-.94,0c0-1.41-.93-2.22-2.7-2.22h-2.35v4.42h2.35C148.89,61.77,149.82,61,149.82,59.57Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M166.24,61.61V56.54h.94v5c0,2.1,1,3.05,2.7,3.05s2.72-1,2.72-3.05v-5h.91v5.07c0,2.53-1.37,3.85-3.63,3.85S166.24,64.14,166.24,61.61Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M184.11,61.23l-1.8,1.83v2.32h-.94V56.54h.94v5.34l5.19-5.34h1.07l-3.82,4,4.09,4.83h-1.11Z"
                    ></path>
                    <path className="cls-1" d="M203.77,56.54h.93v8h5v.81h-5.88Z"></path>
                    <path className="cls-1" d="M216.26,56.54h.93v8.84h-.93Z"></path>
                    <path
                        className="cls-1"
                        d="M234.31,56.54v8.84h-.9V58.31l-3.47,6h-.45L226,58.35v7h-.9V56.54h.77l3.84,6.56,3.8-6.56Z"
                    ></path>
                    <path className="cls-1" d="M242.24,56.54h.93v8.84h-.93Z"></path>
                    <path
                        className="cls-1"
                        d="M252.82,57.35h-3.11v-.81h7.15v.81h-3.11v8h-.93Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M269.66,64.57v.81H263.4V56.54h6.07v.81h-5.13v3.14h4.57v.8h-4.57v3.28Z"
                    ></path>
                    <path
                        className="cls-1"
                        d="M276.91,56.54h3.59c2.83,0,4.74,1.82,4.74,4.42s-1.91,4.42-4.74,4.42h-3.59Zm3.54,8a3.62,3.62,0,1,0,0-7.22h-2.61v7.22Z"
                    ></path>
                    <path
                        stroke="#D0D700"
                        className="cls-2"
                        d="M37,1.56a1,1,0,0,1,.95,1l-.25,16.14a.94.94,0,0,0,1.45.82L53,10.78a1,1,0,0,1,.51-.14.93.93,0,0,1,.82.48l6.3,11.08a1,1,0,0,1-.35,1.29l-13.71,8a1,1,0,0,0,0,1.65l14,8A.94.94,0,0,1,61,42.4l-6.3,11.08a.93.93,0,0,1-.82.48,1.06,1.06,0,0,1-.51-.14L39.4,45.11a.93.93,0,0,0-.5-.14,1,1,0,0,0-.95,1l.25,16.15a1,1,0,0,1-1,1h-12a1,1,0,0,1-1-1l.25-16.15a1,1,0,0,0-1.46-.82L9.18,53.82a1,1,0,0,1-.5.14,1,1,0,0,1-.83-.48L1.56,42.4a.94.94,0,0,1,.36-1.29l14-8a1,1,0,0,0,0-1.65l-14-8a1,1,0,0,1-.36-1.28l6-11.06a1,1,0,0,1,.83-.49.94.94,0,0,1,.51.14l13.9,8.69a.95.95,0,0,0,1.46-.82L24,2.52a1,1,0,0,1,.95-1H37M37,.13H25a2.34,2.34,0,0,0-1.69.71,2.36,2.36,0,0,0-.69,1.7l.24,15.27L9.66,9.58A2.43,2.43,0,0,0,8.4,9.22a2.38,2.38,0,0,0-2.08,1.24l-6,11.06a2.39,2.39,0,0,0,.9,3.2L14.46,32.3,1.21,39.87a2.38,2.38,0,0,0-.89,3.24L6.61,54.18a2.41,2.41,0,0,0,2.07,1.21A2.35,2.35,0,0,0,9.94,55l13.18-8.23-.24,15.26a2.39,2.39,0,0,0,2.38,2.42h12a2.37,2.37,0,0,0,2.37-2.42l-.23-15.26L52.57,55a2.35,2.35,0,0,0,1.26.37,2.38,2.38,0,0,0,2.06-1.21l6.3-11.07a2.39,2.39,0,0,0-.89-3.24L48,32.29l13-7.57a2.38,2.38,0,0,0,.87-3.22L55.6,10.42a2.36,2.36,0,0,0-2.06-1.21,2.43,2.43,0,0,0-1.26.36L39.1,17.81l.23-15.27A2.37,2.37,0,0,0,37,.13Z"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
