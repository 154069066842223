import React from "react";
import {WithServiceState} from "store-fetch-wrappers";
import EditAbsenceForm from "./Components/EditAbsenceForm";
import {useAbsencePage} from "./Hooks/useAbsencePage";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
const ServiceWrapper = WithServiceState(EditAbsenceForm);
const EditAbsence = () => {
    const {store} = useAbsencePage();

    return (
        <React.Fragment>
            <TopBar titleText="Edit Absence" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...store}
                />
            </div>
        </React.Fragment>
    );
};

export default EditAbsence;
